import React, { useState, useEffect, useContext } from "react";
import classes from "./Result.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionHeader from "../../organism/questionHeader/QuestionHeader";
import { DiagnosisResults, fetchDiagnosis } from "../../api/UsersDiagnosisApi";
import { User, fetchUser } from "../../api/UsersApi";
import DiagnosisLoading from "../../organism/diagnosis-loading/DiagnosisLoading";
import charIcon from "../../static/images/MailChara.svg";
import { useAtom } from "jotai";
import { diagnosisLoading } from "../../state/diagnosis-loading/DiagnosisLoadingAtom";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { round } from "../../common/utility/number.util";
import { fetchUsersEducationFee } from "../../api/UsersEducationFeeApi";

//ロゴファイルの動的読み込み
const logos = Array.from({ length: 12 }).map((_, index) => {
  const typeNumber = index + 1;
  return require(`../../static/images/resultLogo${typeNumber}.png`);
});

const messageLogos = Array.from({ length: 9 }).map((_, index) => {
  const typeNumber = index + 1;
  return require(`../../static/images/messageLogo${typeNumber}.png`);
});

const getResultLogoPath = (diagnosisType: string) => {
  const typeNumber = Number(diagnosisType.replace("type", ""));
  return logos[typeNumber - 1];
};

const getMessgaeLogoPath = (typeNumber: number) => {
  return messageLogos[typeNumber - 1];
};

//テキスト文章の改行コード変換
const getNewLineText = (text: string) => {
  return text.split("\n").map((str, index, array) => {
    // 改行のみの行を検出
    if (str === "") {
      // 2つの改行を挿入して、それぞれにユニークなkeyを割り当てる
      return (
        <React.Fragment key={`empty-${index}`}>
          <br key={`br1-${index}`} />
        </React.Fragment>
      );
    }
    // 文字列が含まれる行では、spanで囲み、最後でなければ改行を追加
    return (
      <span key={`line-${index}`}>
        {str}
        {index !== array.length - 1 && <br />}
      </span>
    );
  });
};

const getColorClassForChild = (type: string, currentType: string) => {
  if (type === currentType) {
    switch (type) {
      case "Rule":
        return "blue";
      case "Balance":
        return "green";
      case "Free":
        return "orange";
      default:
        return "gray";
    }
  }
  return "gray";
};

const getColorClassForMoney = (type: string, currentType: string) => {
  if (type === currentType) {
    switch (type) {
      case "Aggressive":
        return "orange";
      case "Balance":
        return "green";
      case "Conservative":
        return "blue";
      default:
        return "gray";
    }
  }
  return "gray";
};

const Result = () => {
  const navigate = useNavigate();

  const diagnosisApi = fetchDiagnosis();
  const fetchUserApi = fetchUser();
  const usersEducationFeeApi = fetchUsersEducationFee();
  const [user, setUser] = useState<User | null>(null);
  const [results, setResults] = useState<DiagnosisResults | null>(null);
  const [loadingState, setLoadingState] = useAtom(diagnosisLoading);

  useEffect(() => {
    fetchUserApi.fetch();
    usersEducationFeeApi.fetch();

    // 画面上部から始まるようにする処理。
    // 全ての画面で利用できるようにすべき？
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (diagnosisLoading) {
      setTimeout(() => {
        setLoadingState(false);
      }, 5000);
    }
  }, [diagnosisLoading]);

  useEffect(() => {
    if (fetchUserApi.state?.data != null) {
      setUser(fetchUserApi.state?.data?.[0]);
    }
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (user == null) {
      return;
    }

    const diagnosisType = user.diagnosisType;

    diagnosisApi.fetch({
      diagnosisType,
    });
  }, [user]);

  useEffect(() => {
    if (diagnosisApi.state?.data == null) {
      return;
    }
    setResults(diagnosisApi.state?.data[0]);
  }, [diagnosisApi.state?.data]);

  return (
    <>
      {loadingState ? (
        <DiagnosisLoading></DiagnosisLoading>
      ) : (
        results != null && (
          <div className={classes.topContainer}>
            <div className={classes.headerContainer}>
              <QuestionHeader />
              <div className={classes.typeContainer}>
                <div className={classes.subTitle}>{results.typeTitle}</div>
                <span className={classes.mainTitle}>{results.typeName}</span>
                <span className={classes.subType}>タイプ</span>
              </div>
              <div className={classes.typeExplanation}>
                <div className={classes.typeFuture}>{results?.typeFeature}</div>
              </div>
              <div className={classes.logoContainer}>
                <img
                  src={getResultLogoPath(results?.diagnosisType)}
                  alt="Logo"
                />
              </div>
            </div>
            <div className={classes.barContainer}>
              <div className={classes.barTitle}>資産形成に対する考え方</div>
              <div className={classes.bar}>
                <div
                  className={`${classes.barLeft} ${classes[getColorClassForMoney("Conservative", results.moneyTendency)]}`}
                >
                  守り
                </div>
                <div
                  className={`${classes.barCenter} ${classes[getColorClassForMoney("Balance", results.moneyTendency)]}`}
                >
                  バランス
                </div>
                <div
                  className={`${classes.barRight} ${classes[getColorClassForMoney("Aggressive", results.moneyTendency)]}`}
                >
                  攻め
                </div>
              </div>
              {/* 子供がいる時限定 */}
              {user?.isChildren && (
                <>
                  <div className={classes.barTitle}>子どもへの金融教育の傾向</div>
                  <div className={classes.bar}>
                    <div
                      className={`${classes.barLeft} ${classes[getColorClassForChild("Rule", results.childTendency)]}`}
                    >
                      ルール
                    </div>
                    <div
                      className={`${classes.barCenter} ${classes[getColorClassForChild("Balance", results.childTendency)]}`}
                    >
                      バランス
                    </div>
                    <div
                      className={`${classes.barRight} ${classes[getColorClassForChild("Free", results.childTendency)]}`}
                    >
                      フリー
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={classes.messageBoxContainer}>
              <div className={classes.messageBox}>
                <h2 className={classes.messageBoxTitle}>
                  資産形成に対する考え方
                </h2>
                <div className={classes.logoContainer}>
                  <img src={getMessgaeLogoPath(1)} alt="Logo" />
                </div>
                <h3 className={classes.messageBoxSubtitle}>
                  {results.moneyTendTitle}
                </h3>
                <p className={classes.messageBoxContent}>
                  {getNewLineText(results.moneyTendExpl)}
                </p>
              </div>
              {/* 子供がいる時限定 */}
              {user?.isChildren && (
                <>
                  <div className={classes.messageBox}>
                    <h2 className={classes.messageBoxTitle}>
                    子どもへの金融教育の傾向
                    </h2>
                    <div className={classes.logoContainer}>
                      <img src={getMessgaeLogoPath(2)} alt="Logo" />
                    </div>
                    <h3 className={classes.messageBoxSubtitle}>
                      {results.childTendTitle}
                    </h3>
                    <p className={classes.messageBoxContent}>
                      {getNewLineText(results.childTendExpl)}
                    </p>
                  </div>
                  <div className={classes.messageBox}>
                    <h2 className={classes.messageBoxTitle}>
                      子どもの金銭感覚への影響
                    </h2>
                    <div className={classes.logoContainer}>
                      <img src={getMessgaeLogoPath(3)} alt="Logo" />
                    </div>
                    <h3 className={classes.messageBoxSubtitle}>
                      {results.impactTitle}
                    </h3>
                    <p className={classes.messageBoxContent}>
                      {getNewLineText(results.impactExpl)}
                    </p>
                  </div>
                </>
              )}
              {/* 3月20日（水）福田さんからのフィードバックで削除
            <div className={classes.educationFinanceContainer}>
              <div className={classes.outerCard}>
                <div className={classes.title}>将来必要な教育資金の目安</div>
                <div className={classes.logoContainer}>
                  <img src={getMessgaeLogoPath(4)} alt="Logo" />
                </div>
                <div className={classes.card}>
                  <div className={classes.cardTitle}>
                    今後の教育に必要なお金の総額
                  </div>
                  <div className={classes.cardBody}>
                    <div className={classes.block}>
                      <span className={classes.value}>
                        {round(
                          (usersEducationFeeApi.state?.data?.[0]
                            .totalTuitionFee ?? 0) / 10000,
                          "ceil",
                          0
                        )}
                      </span>
                      <span className={classes.unit}>万円</span>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardTitle}>
                    子ども全員が大学入学するまでの積立額
                  </div>
                  <div className={classes.cardBody}>
                    <div className={classes.block}>
                      <span className={classes.label}>月額</span>
                      <span className={classes.value}>
                        {round(
                          (usersEducationFeeApi.state?.data?.[0]
                            .totalTuitionFeeMonthlyBased ?? 0) / 10000,
                          "ceil",
                          0
                        )}
                      </span>
                      <span className={classes.unit}>万円</span>
                    </div>
                    <div className={classes.block}>
                      <span className={classes.label}>年間</span>
                      <span className={classes.value}>
                        {round(
                          (usersEducationFeeApi.state?.data?.[0]
                            .totalTuitionFeeYearlyBased ?? 0) / 10000,
                          "ceil",
                          0
                        )}
                      </span>
                      <span className={classes.unit}>万円</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}
              <div className={classes.messageBox}>
                <h2 className={classes.messageBoxTitle}>おすすめアクション</h2>
                <div className={classes.logoContainer}>
                  <img src={getMessgaeLogoPath(5)} alt="Logo" />
                </div>
                <div className={classes.messageSubBoxContent}>
                  <p className={classes.messageSubBoxTitle}>
                  資産形成の成功率を高めるアクション
                  </p>
                  <p className={classes.messageSubBoxTitleExplanation}>
                    {results?.moneyActionTitle}
                  </p>
                </div>
                <p className={classes.messageBoxContent}>
                  {getNewLineText(results?.moneyActionExpl)}
                </p>
                {/* 子供がいる時限定 */}
                {user?.isChildren && (
                  <>
                    <div className={classes.messageSubBoxContent}>
                      <p className={classes.messageSubBoxTitle}>
                        より良い金融教育のためのアクション
                      </p>
                      <p className={classes.messageSubBoxTitleExplanation}>
                        {results.childActionTitle}
                      </p>
                    </div>
                    <p className={classes.messageBoxContent}>
                      {getNewLineText(results.childActionExpl)}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className={classes.diagnosisButton}>
              <SimpleButton
                label="もう一度診断してみる"
                width="80%"
                color="black"
                isRadius={true}
                onClick={() => navigate("/question")}
              ></SimpleButton>
            </div>
            <div className={classes.myPageButton}>
              <SimpleButton
                label="マイページ"
                width="80%"
                color="orange"
                isRadius={true}
                onClick={() => navigate("/my-page")}
              ></SimpleButton>
            </div>
            <BreadCrumb data={["みらいいコンシェル", "おカネの行動診断結果"]} />
          </div>
        )
      )}
    </>
  );
};

export default Result;
